import React from "react";

export default function About() {
  return (
    <div>
      <h2 className="my-3">About Us</h2>
      <p>
        We will in general overlook that satisfaction doesn’t originate from
        something we don’t have, yet rather of perceiving and acknowledging what
        we do… Satisfaction is fellowship… Joy is festivity. It’s tied in with
        acquiring bliss and praising life. Spreading bliss and commending each
        snapshot of life is the mantra at ‘Organization Name’. ‘Organization
        Name’ started their endeavor with Handmade Chocolates and gradually
        moved into Cakes, Pastries, and unified items. Everything started with
        taking up little gathering requests. With the progression of time, we
        moved to counters in markets and now have 50 outlets in the city of LA.
        ‘Organization Name’ develops with each passing day and connecting with
        individuals and adding to mind-blowing festivals In 1995 ‘Organization
        Name’ wandered into advancement and innovativeness and presented
        productive Birthday Cakes, Customized Wedding Cakes, and Theme Cakes
        according to events. ‘Organization Name’ expects to be the main Cake
        Store regarding quality, benefit, esteem for cash, and consumer loyalty.
      </p>
    </div>
  );
}
